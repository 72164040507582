import ScrollMagic from "scrollmagic"

(function () {

  'use strict';

  const directive = { name: 'scrollOffsetAddClass' };

  controller.$inject = [ '$state' ];

  function controller( $state ){

      function link( scope, element, attrs ){

        let isScroll = localStorage.getItem('openHeader') || true;

        if ( isScroll === true ) {
          let controller = new ScrollMagic.Controller();

          new ScrollMagic.Scene({
            triggerElement: element,
            triggerHook: 0,
            offset: 50,
            reverse: false
          })
            .on('enter', function(event) {
              localStorage.setItem('openHeader', 'false');
            })
            .setClassToggle( element, 'is-open' )
            .addTo(controller);
        }
      }

      return {
        restrict: 'A',
        link
      };
  }

  app.directive( directive.name, controller );

})();

import '../../service/domain/game.js';
(function () {
  'use strict';

  const component = { name: 'lindaTournamentGames' };

  controller.$inject = ['$scope', 'game'];

  function controller($scope, _game) {
    $scope.collection = [];
    $scope.preloader = false;
    $scope.content = {};
    $scope.total_count = 0;

    const config = {};

    function getCollection(options) {
      $scope.preloader = true;
      _game.collection(options).then(
        (answer) => {
          $scope.collection = answer.result;
          $scope.total_count = answer.total_count;
          $scope.preloader = false;
        },
        (answer) => {
          $scope.preloader = false;
        }
      );
    }

    this.$onInit = function () {
      $scope.content = this.content;

      config.count = this.count;
      config.out = this.fields;
      config.random = this.random;
      config.tournament = this.tournament;

      getCollection(config);
    };
  }

  app.component(component.name, {
    controller,
    template: app.getTU(component.name),
    bindings: {
      count: '<',
      content: '<',
      fields: '<',
      tournament: '<',
      random: '<',
    },
  });
})();

import { fromEvent } from 'rxjs';
import { tap, delay, switchMap, filter } from 'rxjs/operators';
(function () {
  'use strict';
  const directive = { name: 'quickDepositMouseAction' };
  controller.$inject = ['$timeout'];

  function controller() {
    function link(scope, element, attrs, $timeout) {
      const config = scope.$eval(attrs[directive.name]);
      const mouseenterDelay = config.mouseenterDelay || 400;
      const mouseleaveDelay = config.mouseleaveDelay || 600;
      const hideAnimationDelay = config.hideAnimationDelay || 200;

      const mouseenter$ = fromEvent(element, 'mouseenter');
      const mouseleave$ = fromEvent(element, 'mouseleave');
      const click$ = fromEvent(element, 'click');
      const clickOutside$ = fromEvent(window, 'click');

      let isChanged = false;
      let isHovered = false;

      function hideElemntWithDelay() {
        element.removeClass(config.class);
        element.addClass(config.removeClass);

        $timeout(() => {
          element.removeClass(config.removeClass);
        }, hideAnimationDelay);
      }

      mouseenter$
        .pipe(
          tap(() => {
            isHovered = true;
          }),
          delay(mouseenterDelay),
          filter(() => isHovered),
        )
        .subscribe((e) => {
          element.addClass(config.class);
        });

      mouseleave$
        .pipe(
          tap(() => {
            isHovered = false;
          }),
          delay(mouseleaveDelay),
        )
        .subscribe((e) => {
          if (!isChanged) {
            hideElemntWithDelay();
          }
        });

      click$
        .pipe(
          tap((e) => {
            isChanged = true;
            e.stopPropagation();
          }),
          switchMap((e) => clickOutside$),
        )
        .subscribe((e) => {
          isChanged = false;
          hideElemntWithDelay();
        });

      scope.$on('$destroy', function () {
        mouseenter$.unsubscribe();
        mouseleave$.unsubscribe();
        click$.unsubscribe();
        clickOutside$.unsubscribe();
      });
    }

    return {
      restrict: 'A',
      link: link,
    };
  }

  app.directive(directive.name, controller);
})();

const router = [
  {
    name: 'app.root',
    abstract: true,
    template: '<linda-app linda-zendesk></linda-app>',
  },
  {
    name: 'app.root.layer1.layer2.layer3.paynplay',
    url: '/fast-deposit',
    template: '<linda-zimpler-paynplay-page></linda-zimpler-paynplay-page>',
  },
  {
    name: 'app.root.paymentcb',
    url: '/payment/:act?order_id&identifier',
    template: '<linda-payment-callback ></linda-payment-callback>',
  },

  {
    name: 'app.root.withdraw-paymentcb-success',
    url: '/withdraw-payment/success',
    template: '<linda-withdrawal-payment-callback></linda-withdrawal-payment-callback>',
    params: {
      status: 'success',
    },
  },
  {
    name: 'app.root.withdraw-paymentcb-fail',
    url: '/withdraw-payment/fail',
    template: '<linda-withdrawal-payment-callback></linda-withdrawal-payment-callback>',
    params: {
      status: 'fail',
    },
  },

  // For redirecting to homepage after clicking home button in game iframe
  {
    name: 'app.root.callback',
    url: '/callback/:name',
    template: '<linda-callback-page/>',
  },

  // HEADER
  {
    name: 'app.root.layer1',
    abstract: true,
    template: '<linda-view-layer-one></linda-view-layer-one>',
  },

  // FOOTER
  {
    name: 'app.root.layer1.layer2',
    abstract: true,
    template: '<linda-view-layer-two></linda-view-layer-two>',
  },

  //profile
  {
    name: 'app.root.layer1.layer2.account',
    abstract: true,
    template: '<linda-profile-page></linda-profile-page>',
    private: true,
  },
  {
    name: 'app.root.layer1.layer2.account.staff',
    url: '/account/staff',
    template: '<linda-profile-staff></linda-profile-staff>',
  },
  {
    name: 'app.root.layer1.layer2.account.settings',
    url: '/account/settings',
    template: '<linda-profile-form></linda-profile-form>',
  },
  {
    name: 'app.root.layer1.layer2.account.bonuses',
    url: '/account/bonuses',
    template: '<linda-profile-bonus-list></linda-profile-bonus-list>',
  },
  {
    name: 'app.root.layer1.layer2.account.history',
    abstract: true,
    template: '<ui-view></ui-view>',
  },
  {
    name: 'app.root.layer1.layer2.account.history.casino',
    url: '/account/history/casino',
    template: '<linda-cashbox-history-casino></linda-cashbox-history-casino>',
  },
  {
    name: 'app.root.layer1.layer2.account.history.sport',
    url: '/account/history/sport',
    template: '<linda-cashbox-history-sport></linda-cashbox-history-sport>',
  },
  {
    name: 'app.root.layer1.layer2.account.verification',
    url: '/account/verification',
    template: '<batman-view-layer3></batman-view-layer3>',
  },

  // SUB MENU
  {
    name: 'app.root.layer1.layer2.layer3',
    abstract: true,
    template: '<linda-view-layer-three></linda-view-layer-three>',
  },

  {
    name: 'app.root.layer1.layer2.layer3.not-found',
    url: '/404',
    template: '<not-found></not-found>',
  },

  //PROMOS
  {
    name: 'app.root.layer1.layer2.layer3.promos',
    abstract: true,
    template: '<ui-view></ui-view>',
  },

  {
    name: 'app.root.layer1.layer2.layer3.promos.list',
    url: '/promotions?collection',
    template: '<linda-promos></linda-promos>',
    params: {
      collection: 'all',
    },
  },
  {
    name: 'app.root.layer1.layer2.layer3.promos.page',
    url: '/promotions/:name',
    template: '<linda-promo-page scroll-to-top=""></linda-promo-page>',
  },
  {
    name: 'app.root.layer1.layer2.layer3.vip',
    url: '/vip',
    template: '<linda-vip></linda-vip>',
  },
  {
    name: 'app.root.layer1.layer2.layer3.promo-freebet',
    url: '/terms/free-bet',
    template: '<linda-promotion-rules-page></linda-promotion-rules-page>',
    params: {
      type: 'free-bet',
    },
  },
  {
    name: 'app.root.layer1.layer2.layer3.promo-freespin',
    url: '/terms/freespins',
    template: '<linda-promotion-rules-page></linda-promotion-rules-page>',
    params: {
      type: 'freespin',
    },
  },
  {
    name: 'app.root.layer1.layer2.layer3.promo-casinobonus',
    url: '/terms/casino-bonus',
    template: '<linda-promotion-rules-page></linda-promotion-rules-page>',
    params: {
      type: 'casino-bonus',
    },
  },
  {
    name: 'app.root.layer1.layer2.layer3.halloween',
    url: '/seasons/halloween',
    template: '<halloween-promo class="halloween-unset" scroll-to-top="" ></halloween-promo>',
  },
  {
    name: 'app.root.summer',
    url: '/seasons/summer',
    private: true,
    template: '<summer-promo-wrapper scroll-to-top></summer-promo-wrapper>',
  },
  {
    name: 'app.root.summer-game',
    url: '/seasons-summer',
    private: true,
    template: `<summer-promo-page scroll-to-top></summer-promo-page>`,
  },
  // {
  //   name: 'app.root.layer1.layer2.layer3.seasons',
  //   url: '/seasons/:name',
  //   template: `<easter-promo scroll-to-top></easter-promo>`,
  // },

  //Tournaments
  {
    name: 'app.root.layer1.layer2.layer3.tournament',
    abstract: true,
    template: '<ui-view></ui-view>',
  },
  {
    name: 'app.root.layer1.layer2.layer3.tournament.list',
    url: '/tournaments',
    template: '<batman-tournament-list1 players="true" competitors="5" scroll-to-top></batman-tournament-list1>',
  },
  {
    name: 'app.root.layer1.layer2.layer3.sporttournament',
    url: '/sport-tournament',
    template: '<batman-view-layer2 scroll-to-top></batman-view-layer2>',
    params: {
      name: 'soccer-libra-tournament',
      hasLeagues: true,
    },
  },
  {
    name: 'app.root.layer1.layer2.layer3.season-tournament',
    url: '/australian-open',
    template: '<batman-view-layer2 scroll-to-top></batman-view-layer2>',
    params: {
      hasBanner: true,
      champids: '3013',
      name: 'australian-open',
    },
  },
  {
    name: 'app.root.layer1.layer2.layer3.tournament.page',
    url: '/tournaments/:name',
    template: '<linda-tournament-page scroll-to-top></linda-tournament-page>',
  },

  // CASINO SECTION
  {
    name: 'app.root.layer1.layer2.layer3.casino',
    abstract: true,
    template: '<ui-view></ui-view>',
  },

  // <MACHINES> ...
  {
    name: 'app.root.layer1.claw_games',
    abstract: true,
    private: true,
    template: '<linda-view-layer-three></linda-view-layer-three>',
  },
  {
    name: 'app.root.layer1.claw_games.gamepage',
    url: '/machine/:name',
    template: '<batman-view-layer1 />',
  },
  //- </MACHINES>

  /*
    {
      name: 'app.root.layer1.layer2.layer3.cash-crab',
      url: '/play/cash-crab',
      template: '<batman-view-layer1 />',
      private: true,
    },
    {
      name: 'app.root.layer1.layer2.layer3.cash_crab',
      url: '/play/cash_crab',
      template: '<batman-view-layer2 />',
      private: true,
    },

   */

  //game-page
  {
    name: 'app.root.layer1.layer2.layer3.casino.game',
    abstract: true,
    template: '<linda-game-page scroll-to-top=""></linda-game-page>',
    gamePage: true,
  },
  {
    name: 'app.root.layer1.layer2.layer3.casino.game.real',
    url: '/play/:name',
    template: '<linda-real-game-footer game="game"></linda-real-game-footer>',
    private: true,
    params: {
      mode: 'real',
    },
  },
  {
    name: 'app.root.layer1.layer2.layer3.casino.game.demo',
    url: '/game/:name',
    template: '<linda-demo-game-footer  game="game"></linda-demo-game-footer>',
    params: {
      mode: 'demo',
    },
  },

  {
    name: 'app.root.layer1.layer2.layer3.casino.section',
    abstract: true,
    template: '<linda-view-layer-four></linda-view-layer-four>',
  },
  {
    name: 'app.root.layer1.layer2.layer3.casino.section.home',
    url: '/casino',
    template: '<linda-homepage></linda-homepage>',
  },
  {
    name: 'app.root.layer1.layer2.layer3.casino.section.catalog',
    abstract: true,
    template: '<linda-casino-section></linda-casino-section>',
  },
  {
    name: 'app.root.layer1.layer2.layer3.casino.section.catalog.favourites',
    url: '/games/favourites',
    template: '<batman-game-box-favourites1></batman-game-box-favourites1>',
    private: true,
    params: {
      name: 'favourites',
    },
  },
  {
    name: 'app.root.layer1.layer2.layer3.casino.section.catalog.last',
    url: '/games/last-played',
    template: '<batman-recently-played-list1></batman-recently-played-list1>',
    private: true,
    page: true,
    params: {
      name: 'last-played',
    },
  },
  {
    name: 'app.root.layer1.layer2.layer3.casino.section.catalog.games',
    url: '/games/:name',
    template: '<linda-game-hall></linda-game-hall>',
  },
  {
    name: 'app.root.layer1.layer2.layer3.casino.section.catalog.providers',
    url: '/casino-providers/:name',
    template: '<linda-game-hall-providers></linda-game-hall-providers>',
    params: {
      pageName: 'providers',
      pageType: '',
    },
  },

  //-------------------SPORT--------------------
  {
    name: 'app.root.layer1.layer2.layer3.sportpage',
    url: '/sport',
    template: '<linda-sport-page></linda-sport-page>',
  },
  {
    name: 'app.root.layer1.layer2.layer3.sportpage.prelive',
    url: '/prelive?sportids&catids&champids&tabs&eventid&betslipoddids',
    template: '',
    params: {
      page: 'prelive',
    },
  },
  {
    name: 'app.root.layer1.layer2.layer3.sportpage.live',
    url: '/live?sportids&catids&champids&tabs&eventid&betslipoddids',
    template: '',
    params: {
      page: 'live',
    },
  },
  {
    name: 'app.root.layer1.layer2.layer3.sportpage.virtual',
    url: '/:page?sportids&catids&champids&tabs&eventid&betslipoddids',
    template: '',
    params: {
      page: 'vflm',
    },
  },

  //INFO PAGES

  {
    name: 'app.root.layer1.layer2.layer3.sitemap',
    url: '/sitemap',
    template: '<linda-sitemap scroll-to-top=""></linda-sitemap>',
  },
  {
    name: 'app.root.layer1.layer2.layer3.contact-us',
    url: '/contact-us',
    template: '<linda-info-page scroll-to-top=""></linda-info-page>',
    params: {
      pageName: 'info',
      name: 'contact-us',
    },
  },
  {
    name: 'app.root.layer1.layer2.layer3.privacy-policy',
    url: '/privacy-policy',
    template: '<linda-info-page scroll-to-top=""></linda-info-page>',
    params: {
      pageName: 'info',
      name: 'privacy-policy',
    },
  },
  {
    name: 'app.root.layer1.layer2.layer3.cookies-policy',
    url: '/cookies-policy',
    template: '<linda-info-page scroll-to-top=""></linda-info-page>',
    params: {
      pageName: 'info',
      name: 'cookies-policy',
    },
  },
  {
    name: 'app.root.layer1.layer2.layer3.about',
    url: '/about',
    template: '<linda-info-page scroll-to-top=""></linda-info-page>',
    params: {
      pageName: 'info',
      name: 'about',
    },
  },
  {
    name: 'app.root.layer1.layer2.layer3.responsible-gaming',
    url: '/responsible-gaming',
    template: '<linda-info-page scroll-to-top=""></linda-info-page>',
    params: {
      pageName: 'info',
      name: 'responsible-gaming',
    },
  },
  {
    name: 'app.root.layer1.layer2.layer3.rules',
    url: '/rules',
    template: '<linda-info-page scroll-to-top=""></linda-info-page>',
    params: {
      pageName: 'info',
      name: 'rules',
    },
  },
  {
    name: 'app.root.layer1.layer2.layer3.faq',
    url: '/faq',
    template: '<linda-info-page scroll-to-top=""></linda-info-page>',
    params: {
      pageName: 'info',
      name: 'faq',
    },
  },

  {
    name: 'app.root.missed-data',
    url: '/missed-data',
    template: '<linda-missed-data-form></linda-missed-data-form>',
  },

  //HOME
  {
    name: 'app.root.layer1.layer2.layer3.home',
    url: '/',
    template: '<linda-landing></linda-landing>',
  },
];

export { router };

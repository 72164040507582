import { $_PGW } from "./pgw.mjs";

var $_CONFIG = $_CONFIG || {};

$_CONFIG.sport = true; // присутність на проекті спорта (true|false)
$_CONFIG.sportVersion = 3;
$_CONFIG.sportId = 'librabet';

$_CONFIG.domain = 'https://librabet.devv2.space'; // домен апі (рекомендовано залишати пустим)

$_CONFIG.cdn = '/joxi'; // домен сервера cdn (поки один на всі проекти)
$_CONFIG.siteDomain = 'https://librabet.com';

$_CONFIG.siteName = 'librabet';
$_CONFIG.mobile = false;

$_CONFIG.redirectToNotFound = false;
$_CONFIG.pgw = $_PGW;

$_CONFIG.mid = null; // id пікселя для інкам. ВАЖЛИВО! В кожного сайта своє

$_CONFIG.googleAnalytics = {
  appId: 'UA-128362036-1',
  trackAs: 'librabet',
};

$_CONFIG.symplify = true;

$_CONFIG.sportBanners = {
  bottomRight: {
    iframe: `${$_CONFIG.cdn}/js/crab/sdk/views/widget-sport.html?build=${Date.now()}`,
    options: {
      height: '486px',
    },
  },
};

$_CONFIG.scss = {
  // об'єкт, який передається в scss при компіляції
  test: 'test',
};

export { $_CONFIG };

import '../../../service/domain/bank.js';
import '../../../service/helper.js';
import '../../../service/domain/payment.js';
import '../../../service/configs/entercash.js';
import '../../../service/configs/config.js';
import '../../../service/domain/state.js';
import '../../../service/domain/modal.js';
(function () {
  'use strict';

  const component = { name: 'lindaRegistrationBankPage' };

  controller.$inject = [
    '$scope',
    'bank',
    'helper',
    'payment',
    'config',
    '$timeout',
    'state',
    'modal',
    'entercash.config',
    '$state',
  ];

  function controller($scope, _bank, _helper, _payment, _config, $timeout, _state, _modal, _entercash_config, $state) {
    let mode = 'registration';

    $scope.page = 1;
    $scope.url = '/preloader.html';

    $scope.currencyList = _entercash_config.currencyList;
    $scope.countries = _entercash_config.countries;

    const defaultAmount = _entercash_config.defaultAmount;

    $scope.formData = {
      paymethod: 'entercash',
      country: $scope.countries[_config.currentLang].code,
      currency: $scope.currencyList[_config.currentLang].key,
      amount: defaultAmount[_config.currentLang],
    };

    $scope.defaultAmountRange = _entercash_config.defaultAmountRange[_config.currentLang];
    $scope.amountRange = _entercash_config.amountRange[_config.currentLang];

    if ($state.params && $state.params.amount) {
      $scope.formData.amount = parseInt($state.params.amount);
    }
    if ($state.params && $state.params.currency) {
      $scope.formData.currency = $state.params.currency;
    }

    const o = {
      paymethod: 'entercash',
      token: null,
    };

    $scope.preloader = false;

    function deposit(answer) {
      mode = 'deposit';

      _payment
        .deposit({
          amount: $scope.formData.amount,
          paymethod: $scope.formData.paymethod,
          instantplay: true, // flag for backend
        })
        .then(
          (answer) => {
            $scope.url = answer.result.url;
            $timeout(() => {
              $scope.preloader = false;
            }, 500);
          },
          (answer) => {
            $scope.preloader = false;
          }
        );
    }

    // kostyl
    function got_message(m) {
      //-            $scope.preloader = true;

      if (m.data.type === 'ec:state:change') {
        switch (m.data.state) {
          case 'AUTHENTICATE':
            break;

          case 'SELECT_BANK':
            $timeout(() => {
              $scope.preloader = false;
            }, 0);
            break;

          case 'CANCEL':
            $timeout(() => {
              $scope.preloader = false;
              $scope.url = '';

              $scope.page = 1;
            }, 0);

            break;

          case 'OK':
            if (mode === 'registration') {
              $scope.url = '';
              $scope.preloader = true;

              _bank.auth(o).then(deposit, (answer) => {
                $scope.preloader = false;

                if (answer.messages[0].code === 1114) {
                  _modal.open('mail-change', o).then(deposit, (answer) => {});
                }
              });
            } else if (mode === 'deposit') {
              $scope.url = '';
              _state.goto('home');
            }

            break;
        }
      }
    }

    $scope.submit = _helper.form.validator((form) => {
      $scope.preloader = true;

      _bank.init($scope.formData).then(
        (answer) => {
          $scope.page = 2;
          $scope.url = answer.result.url;
          o.token = answer.result.token;
          //-                    $scope.preloader = false;
        },
        (answer) => {
          $scope.preloader = false;
        }
      );
    });

    this.$onInit = () => {
      window.addEventListener('message', got_message);
    };

    this.$onDestroy = () => {
      window.removeEventListener('message', got_message);
    };
  }

  app.component(component.name, {
    controller,
    template: app.getTU(component.name),
  });
})();

import '../../../service/domain/history.js';
(function () {
  'use strict';

  const component = { name: 'lindaCashboxHistorySport' };

  controller.$inject = ['$scope', 'history'];

  function controller($scope, _history) {
    $scope.filter = {
      period: 'week',
      datefrom: _history.getFormattedYesterday(),
      dateto: _history.getFormattedToday(),
    };

    $scope.renew = () => {
      const opt = {
        params: $scope.filter,
        endpoint: 'sport',
      };

      $scope.preloader = true;
      _history.collection(opt).then(
        (answer) => {
          $scope.preloader = false;
          $scope.collection = answer.result;
        },
        (answer) => {
          $scope.preloader = false;
        }
      );
    };

    $scope.renew();
  }

  app.component(component.name, {
    controller,
    template: app.getTU(component.name),
  });
})();

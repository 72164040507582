const aliases = {
  home: {
    page: true,
    name: 'app.root.layer1.layer2.layer3.home',
  },
  promo: {
    page: true,
    name: 'app.root.layer1.layer2.layer3.promos.list',
  },
  login: {
    page: false,
    name: 'login',
  },
  registration: {
    page: false,
    name: 'registration',
  },
  deposit: {
    private: true,
    page: false,
    name: 'cashbox',
  },
  getBonus: {
    private: true,
    page: false,
    name: 'getbonus',
  },
  realGame: {
    page: true,
    name: 'app.root.layer1.layer2.layer3.casino.game.real',
  },
  tournaments: {
    page: true,
    name: 'app.root.layer1.layer2.layer3.tournament.list',
  },
  paynplay: {
    page: true,
    name: 'app.root.layer1.layer2.layer3.paynplay',
  },
  notFound: {
    page: true,
    name: 'app.root.layer1.layer2.layer3.not-found',
  },
  missedData: {
    page: true,
    name: 'app.root.missed-data',
  },
  withdrawal: {
    page: false,
    name: 'cashbox',
    private: true,
    content: {
      tab1: 2,
    },
  },
  contact: {
    page: true,
    name: 'app.root.layer1.layer2.layer3.contact-us',
  },

  bonus_crab: {
    page: true,
    name: 'app.root.layer1.claw_games.gamepage',
    params: {
      name: 'cash-crab',
    },
  },
  cash_crab: {
    page: true,
    name: 'app.root.layer1.claw_games.gamepage',
    params: {
      name: 'cash_crab',
    },
  },
  claw: {
    page: true,
    name: 'app.root.layer1.claw_games.gamepage',
  },
  oktoberfest: {
    page: true,
    name: 'app.root.layer1.claw_games.gamepage',
    params: {
      name: 'oktoberfest',
    },
  },

  christmasPromotionRaffle: {
    page: true,
    name: 'app.root.layer1.layer2.layer3.promos.page',
    params: {
      name: 'frostival-prizes',
    },
  },

  christmasPromotion: {
    page: true,
    name: 'app.root.layer1.layer2.layer3.promos.page',
    params: {
      name: 'million-frostival-spins',
    },
  },

  profile: {
    private: true,
    page: true,
    name: 'app.root.layer1.layer2.account.staff',
  },
  history: {
    page: false,
    name: 'profile',
    content: { tab1: 2 },
    private: true,
  },
  bonuses: {
    private: true,
    page: true,
    name: 'app.root.layer1.layer2.account.bonuses',
  },
  bonus: {
    private: true,
    page: true,
    name: 'app.root.layer1.layer2.account.bonuses',
  },
  terms: {
    page: true,
    name: 'app.root.layer1.layer2.layer3.rules',
  },
  pragmatic: {
    page: true,
    name: 'app.root.layer1.layer2.layer3.casino.section.catalog.providers',
    params: {
      name: 'pragmatic',
    },
  },
  threeoaks: {
    page: true,
    name: 'app.root.layer1.layer2.layer3.casino.section.catalog.providers',
    params: {
      name: 'threeoaks',
    },
  },
  spinomenal: {
    page: true,
    name: 'app.root.layer1.layer2.layer3.casino.section.catalog.providers',
    params: {
      name: 'spinomenal',
    },
  },
  ela: {
    page: true,
    name: 'app.root.layer1.layer2.layer3.casino.section.catalog.providers',
    params: {
      name: 'ela',
    },
  },
  playngo: {
    page: true,
    name: 'app.root.layer1.layer2.layer3.casino.section.catalog.providers',
    params: {
      name: 'playngo',
    },
  },
  relax: {
    page: true,
    name: 'app.root.layer1.layer2.layer3.casino.section.catalog.providers',
    params: {
      name: 'relax',
    },
  },
  skywind: {
    page: true,
    name: 'app.root.layer1.layer2.layer3.casino.section.catalog.providers',
    params: {
      name: 'skywind',
    },
  },
  netent: {
    page: true,
    name: 'app.root.layer1.layer2.layer3.casino.section.catalog.providers',
    params: {
      name: 'netent',
    },
  },
  pushgaming: {
    page: true,
    name: 'app.root.layer1.layer2.layer3.casino.section.catalog.providers',
    params: {
      name: 'pushgaming',
    },
  },
  playtech: {
    page: true,
    name: 'app.root.layer1.layer2.layer3.casino.section.catalog.providers',
    params: {
      name: 'playtech',
    },
  },
  quickspin: {
    page: true,
    name: 'app.root.layer1.layer2.layer3.casino.section.catalog.providers',
    params: {
      name: 'quickspin',
    },
  },
  redtiger: {
    page: true,
    name: 'app.root.layer1.layer2.layer3.casino.section.catalog.providers',
    params: {
      name: 'redtiger',
    },
  },
  hacksaw: {
    page: true,
    name: 'app.root.layer1.layer2.layer3.casino.section.catalog.providers',
    params: {
      name: 'hacksaw',
    },
  },
  evolution: {
    page: true,
    name: 'app.root.layer1.layer2.layer3.casino.section.catalog.providers',
    params: {
      name: 'evolution',
    },
  },
  gameProvider: {
    page: true,
    name: 'app.root.layer1.layer2.layer3.casino.section.catalog.providers',
  },
  halloween: {
    page: true,
    name: 'app.root.layer1.layer2.layer3.tournament.page',
    params: {
      name: 'halloween',
    },
  },
  sport: {
    page: true,
    name: 'app.root.layer1.layer2.layer3.sportpage.prelive',
  },
  bet: {
    page: true,
    name: 'app.root.layer1.layer2.layer3.sportpage.prelive',
  },
  virtual: {
    page: true,
    name: 'app.root.layer1.layer2.layer3.sportpage.virtual',
  },
  games: {
    page: true,
    name: 'app.root.layer1.layer2.layer3.casino.section.catalog.games',
    params: {
      name: 'all-games',
      collection: 'categories',
    },
  },
  live: {
    page: true,
    name: 'app.root.layer1.layer2.layer3.casino.section.catalog.games',
    params: {
      name: 'live-casino',
      collection: 'categories',
    },
  },
  dropwins: {
    page: true,
    name: 'app.root.layer1.layer2.layer3.casino.section.catalog.games',
    params: {
      name: 'dropwins',
      collection: 'categories',
    },
  },
  football: {
    page: true,
    name: 'app.root.layer1.layer2.layer3.sportpage.prelive',
    params: {
      sportids: '66',
      page: 'prelive',
    },
  },
  tenis: {
    page: true,
    name: 'app.root.layer1.layer2.layer3.sportpage.prelive',
    params: {
      sportids: '68',
      page: 'prelive',
    },
  },
  soccer: {
    page: true,
    name: 'app.root.layer1.layer2.layer3.sportpage.prelive',
    params: {
      sportids: '66',
      page: 'prelive',
    },
  },
  tennis: {
    page: true,
    name: 'app.root.layer1.layer2.layer3.sportpage.prelive',
    params: {
      sportids: '68',
      page: 'prelive',
    },
  },
  tabletenis: {
    page: true,
    name: 'app.root.layer1.layer2.layer3.sportpage.prelive',
    params: {
      sportids: '77',
      page: 'prelive',
    },
  },
  basketball: {
    page: true,
    name: 'app.root.layer1.layer2.layer3.sportpage.prelive',
    params: {
      sportids: '67',
      page: 'prelive',
    },
  },
  icehockey: {
    page: true,
    name: 'app.root.layer1.layer2.layer3.sportpage.prelive',
    params: {
      sportids: '70',
      page: 'prelive',
    },
  },
  esports: {
    page: true,
    name: 'app.root.layer1.layer2.layer3.sportpage.prelive',
    params: {
      sportids: '145',
      page: 'prelive',
    },
  },
  formula1: {
    page: true,
    name: 'app.root.layer1.layer2.layer3.sportpage.prelive',
    params: {
      sportids: '103',
      page: 'prelive',
    },
  },
  mma: {
    page: true,
    name: 'app.root.layer1.layer2.layer3.sportpage.prelive',
    params: {
      sportids: '84',
      page: 'prelive',
    },
  },
  cricket: {
    page: true,
    name: 'app.root.layer1.layer2.layer3.sportpage.prelive',
    params: {
      sportids: '149',
      page: 'prelive',
    },
  },
  americanfootball: {
    page: true,
    name: 'app.root.layer1.layer2.layer3.sportpage.prelive',
    params: {
      sportids: '75',
      page: 'prelive',
    },
  },
  baseball: {
    page: true,
    name: 'app.root.layer1.layer2.layer3.sportpage.prelive',
    params: {
      sportids: '76',
      page: 'prelive',
    },
  },
  seasonTournament: {
    page: true,
    private: true,
    name: 'app.root.layer1.layer2.layer3.sportpage.prelive',
    params: {
      champids: '3013',
    },
  },
  summer: {
    page: true,
    name: 'app.root.summer',
  },
  summerGame: {
    page: true,
    name: 'app.root.summer-game',
  },
  promotions: {
    page: true,
    name: 'app.root.layer1.layer2.layer3.promos.list',
  },
  promos: {
    page: true,
    name: 'app.root.layer1.layer2.layer3.promos.list',
  },
};

export { aliases };

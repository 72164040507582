import '../../../service/domain/article.js'
(function () {
  controller.$inject = ['$scope', 'article'];

  function controller($scope, _article) {
    $scope.article = {};
    $scope.preloader = false;

    this.$onChanges = function () {
      if (typeof this.name !== 'undefined') {
        $scope.preloader = true;

        _article
          .item({ name: this.name })
          .then((a) => {
            $scope.article = a.result;
          })
          .catch((e) => {})
          .finally(() => {
            $scope.preloader = false;
          });
      }
    };
  }

  new Controller('article', controller, { name: '<' });
})();

(function () {
  [1, 2, 3, 4, 5, 6, 7, 8, 9].forEach((i) => {
    new Batman(`batmanArticle${i}`, 'article');
  });
})();

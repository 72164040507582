const $_PGW = {
  enabled: true,
  layoutMode: 'column',
  theme: 'light',
  hideCloseButton: true,
  cssVars: {
    '--pgw-font-family': 'SegoeUI, Helvetica, sans-serif',
    '--pgw-background-primary': '#FFFFFF',
    '--pgw-surface-primary': '#FFFFFF',
    '--pgw-surface-secondary': '#F7F7F7',
    '--pgw-surface-secondaryHover': '#F0F0F0',
    '--pgw-surface-accent': '#E9F6F2',
    '--pgw-surface-accentHover': '#BDE5D8',
    '--pgw-surface-buttonPrimary': '#2547FD',
    '--pgw-surface-buttonPrimaryHover': '#1736DB',
    '--pgw-surface-buttonPrimaryPress': '#1831B8',
    '--pgw-surface-buttonPrimaryDisabled': '#A1ADEE',
    '--pgw-surface-buttonSecondary': '#26B283',
    '--pgw-surface-buttonSecondaryHover': '#0FA070',
    '--pgw-surface-buttonSecondaryPress': '#0D875F',
    '--pgw-surface-buttonSecondaryDisabled': '#A3DCC9',
    '--pgw-surface-buttonTertiary': '#F0F0F0',
    '--pgw-surface-buttonTertiaryHover': '#E9E9E9',
    '--pgw-surface-buttonTertiaryPress': '#D3D3D3',
    '--pgw-surface-buttonTertiaryDisabled': '#F7F7F7',
    '--pgw-surface-inputPrimary': '#F7F7F7',
    '--pgw-surface-inputSecondary': '#FFFFFF',
    '--pgw-surface-inputTertiary': '#F0F0F0',
    '--pgw-surface-tab': '#F0F0F0',
    '--pgw-surface-tabHover': '#26B283',
    '--pgw-surface-tabPress': '#0D875F',
    '--pgw-surface-negative': '#FF446C',
    '--pgw-surface-positiveAlpha': 'rgba(177, 219, 0, 0.10)',
    '--pgw-surface-skeleton': '#F7F7F7',
    '--pgw-text-primary': '#000000',
    '--pgw-text-secondary': '#6A6A6A',
    '--pgw-text-tertiary': '#D3D3D3',
    '--pgw-text-button': '#FFFFFF', // will be replaced by '--pgw-text-buttonPrimary, please keep both
    '--pgw-text-buttonDisabled': '#3C3F42', // will be replaced by '--pgw-text-buttonPrimaryDisabled, please keep both
    '--pgw-text-buttonPrimary': '#FFFFFF',
    '--pgw-text-buttonPrimaryDisabled': '#F0F0F0',
    '--pgw-text-buttonSecondary': '#FFFFFF',
    '--pgw-text-buttonSecondaryDisabled': '#F0F0F0',
    '--pgw-text-positive': '#98BB07',
    '--pgw-text-warning': '#E1B000',
    '--pgw-text-negative': '#FF446C',
    '--pgw-text-constant': '#FFF',
    '--pgw-icon-primary': '#000000',
    '--pgw-icon-secondary': '#878787',
    '--pgw-icon-tertiary': '#D3D3D3',
    '--pgw-icon-button': '#323437',
    '--pgw-icon-buttonDisabled': '#4F5157',
    '--pgw-icon-buttonPrimary': '#FFFFFF', // will be replaced by '--pgw-icon-buttonPrimary, please keep both
    '--pgw-icon-buttonDisabledPrimary': '#D5E3F3', // will be replaced by '--pgw-icon-buttonPrimaryDisabled, please keep both
    '--pgw-icon-buttonPrimaryDisabled': '#D5E3F3',
    '--pgw-icon-positive': '#98BB07',
    '--pgw-icon-warning': '#E1B000',
    '--pgw-icon-negative': '#FF446C',
    '--pgw-stroke-primary': 'transparent',
    '--pgw-stroke-secondary': '#46484D',
    '--pgw-stroke-secondaryHover': '#46484D',
    '--pgw-stroke-inputPrimary': '#4F5157',
    '--pgw-stroke-inputSecondary': '#E9E9E9',
    '--pgw-stroke-accent': '#007F97',
    '--pgw-stroke-accentHover': '#0095B0',
    '--pgw-stroke-positive': '#64BA62',
    '--pgw-stroke-positiveAlpha': 'rgba(100, 186, 98, 0.30)',
    '--pgw-stroke-warning': '#FCA209',
    '--pgw-stroke-negative': '#E8544C',
    '--pgw-divider-primary': '#4F5157',

    '--pgw-border-radius-s': '100px',
    '--pgw-border-radius-m': '8px',
    '--pgw-border-radius-l': '12px',
    '--pgw-border-radius-xl': '16px',
  },
};
export { $_PGW };

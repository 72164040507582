(function () {
    'use strict';

    const directive = { name: 'libraCheckedWb' };

    controller.$inject = ['$location', '$state'];

    function controller($location, $state) {
        const sportBonuses = [];
        const casinoBonuses = [];
        const userAffiliateBonus = $location.search();

        function link(scope, element, attrs) {
            function setBonus(collection) {
                scope.formData.trigger_codes = collection[0].trigger_codes;
                scope.activeBonus = collection[0].name;
                scope.activeBonusType = collection[0].type;
                scope.$parent.bonusSelect = collection[0].trigger_codes;
            }
            scope.$watchCollection('promo', function () {
                if (userAffiliateBonus.bonus && scope.promo.length) {
                    const bonusForUser = scope.promo.filter((bonus) => {
                        if (bonus.type === userAffiliateBonus.bonus) return bonus;
                    });
                    if (bonusForUser.length) {
                        setBonus(bonusForUser);
                    }
                    return;
                }
                if (scope.promo.length && scope.promo[0].trigger_codes) {
                    scope.promo.filter((bonus) => {
                        if (bonus.type === 'sport') {
                            sportBonuses.push(bonus);
                            return;
                        }
                        casinoBonuses.push(bonus);
                    });

                    if ($state.params.casinoBonus) {
                        setBonus(casinoBonuses);
                        return;
                    }
                    setBonus(sportBonuses);
                }
            });
        }

        return {
            restrict: 'A',
            link,
        };
    }

    app.directive(directive.name, controller);
})();